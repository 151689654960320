import React, { useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, useGLTF } from '@react-three/drei';
import Menu from '../Menu';
import './ModelViewer.css';

const ModelViewer = ({ modelPath }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const Model = () => {
    const { scene } = useGLTF(`${process.env.PUBLIC_URL}${modelPath}`, true, (error) => {
      setError('Failed to load model');
      console.error(error);
    });
    setLoading(false);
    return <primitive object={scene} scale={0.07} />;
  };

  return (
    <div className="model-viewer-container">
      <Menu />  {/* Menú de navegación superior */}
      {loading && !error && <div className="loading-message">Loading model...</div>}
      <Canvas
        className="canvas"
        camera={{ position: [0, 2, 5], fov: 50 }}
        onCreated={() => setLoading(false)}
      >
        <Suspense fallback={null}>
          <Environment preset="sunset" />
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} intensity={1.5} />
          {!error ? <Model /> : null}
          <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />
        </Suspense>
      </Canvas>
      {error && <div className="error-message">{error}</div>}
      <div className="controls">
        <p>Use the mouse or touch to rotate, zoom, and pan the model.</p>
      </div>
    </div>
  );
};

export default ModelViewer;
