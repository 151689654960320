import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import './Chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [questionButtonsVisible, setQuestionButtonsVisible] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [threadId, setThreadId] = useState('');

  const userName = 'You';
  const botName = 'AI Assistant';

  useEffect(() => {
    createThread();
  }, []);

  const createThread = async () => {
    try {
      const res = await fetch(`https://api.openai.com/v1/threads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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',
          'OpenAI-Beta': 'assistants=v2'
        }
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(`Error en la creación del hilo: ${res.status} - ${errorData.error?.message || res.statusText}`);
      }

      const data = await res.json();
      console.log("Respuesta de creación del hilo:", data);
      if (data && data.id) {
        setThreadId(data.id);
        setMessages([]); // Reinicia los mensajes
        setQuestionButtonsVisible(true); // Muestra los botones de pregunta
        return data.id;
      } else {
        throw new Error("No se recibió un ID de hilo válido.");
      }
    } catch (error) {
      console.error('Error al crear el hilo:', error);
      // Aquí podrías actualizar el estado para mostrar un mensaje de error al usuario
      return null;
    }
  };

  const sendMessage = async (message = input) => {
    if (typeof message !== 'string' || !message.trim()) {
      return;
    }

    if (!threadId) {
      console.log("Creando hilo porque aún no se ha creado...");
      const newThreadId = await createThread();
      if (!newThreadId) {
        console.error("Fallo al crear el hilo.");
        return;
      }
    }

    setIsSending(true);
    const updatedMessages = [...messages, { role: "user", content: message, timestamp: Date.now() }];
    setMessages(updatedMessages);
    setInput('');
    setQuestionButtonsVisible(false);

    try {
      // Enviar mensaje al hilo
      const messageResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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',
          'OpenAI-Beta': 'assistants=v2'
        },
        body: JSON.stringify({
          role: "user",
          content: message
        }),
      });

      if (!messageResponse.ok) {
        throw new Error(`Error al enviar el mensaje: ${messageResponse.status}`);
      }

      // Iniciar una ejecución del asistente
      const runResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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',
          'OpenAI-Beta': 'assistants=v2'
        },
        body: JSON.stringify({
          assistant_id: "asst_hhRyzZvA1OO9VZwpdlHleje0"
        }),
      });

      if (!runResponse.ok) {
        throw new Error(`Error al iniciar la ejecución: ${runResponse.status}`);
      }

      const runData = await runResponse.json();
      await waitForRunCompletion(runData.id);

      // Obtener los mensajes más recientes
      const messagesResponse = await fetch(`https://api.openai.com/v1/threads/${threadId}/messages`, {
        headers: {
          'Authorization': 'Bearer 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',
          'OpenAI-Beta': 'assistants=v2'
        }
      });

      if (!messagesResponse.ok) {
        throw new Error(`Error al obtener los mensajes: ${messagesResponse.status}`);
      }

      const messagesData = await messagesResponse.json();
      const lastMessage = messagesData.data[0];

      setMessages(prevMessages => [...prevMessages, { role: "assistant", content: lastMessage.content[0].text.value, timestamp: Date.now() }]);
    } catch (error) {
      console.error('Error en el proceso de envío de mensaje:', error);
      setMessages(prevMessages => [...prevMessages, { role: "error", content: "Lo siento, hubo un error al procesar tu mensaje. Por favor, intenta de nuevo.", timestamp: Date.now() }]);
    } finally {
      setIsSending(false);
    }
  };

  const waitForRunCompletion = async (runId) => {
    let runStatus = null;
    do {
      const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
        headers: {
          'Authorization': 'Bearer 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',
          'OpenAI-Beta': 'assistants=v2'
        }
      });
      const runData = await response.json();
      runStatus = runData.status;
      if (runStatus === 'completed') {
        return runData;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    } while (runStatus !== 'failed' && runStatus !== 'expired');
    throw new Error(`Run failed with status: ${runStatus}`);
  };

  const selectQuestion = (question) => {
    setInput(question);
    setQuestionButtonsVisible(false);
    sendMessage(question);
  };

  const checkEnter = (event) => {
    if (event.key === 'Enter' && !isSending) {
      sendMessage();
    }
  };

  return (
    <div className="chatbot-container">
      <Menu />
      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index}>
            <strong>{msg.role === 'user' ? userName : botName}:</strong> {msg.content}
          </div>
        ))}
      </div>
      {questionButtonsVisible && (
        <div className="question-buttons">
          <button className='button-quiz' onClick={() => selectQuestion('What method did Chávez first use in his attempt to gain power in Venezuela?')}>What method did Chávez first use in his attempt to gain power in Venezuela?</button>
          <button className='button-quiz' onClick={() => selectQuestion('Approximately how many private companies were expropriated by Chávez during his presidency?')}>Approximately how many private companies were expropriated by Chávez during his presidency?</button>
          <button className='button-quiz' onClick={() => selectQuestion('What happened during the most recent elections in Venezuela?')}>What happened during the most recent elections in Venezuela?</button>
          <button className='button-quiz' onClick={() => selectQuestion('What are common outcomes experienced under socialist or communist regimes?')}>What are common outcomes experienced under socialist or communist regimes?</button>
        </div>
      )}
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={checkEnter}
        className="chat-input"
        placeholder="Type your message..."
        disabled={isSending}
      />
      <div className="question-buttons">
        <button className='button-quiz' onClick={() => sendMessage()} disabled={isSending}>Submit</button>
        <button className='button-quiz' onClick={createThread}>New conversation</button>
      </div>
    </div>
  );
};

export default Chatbot;