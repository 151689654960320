import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from '../Menu'; // Importa el componente del menú
import './QuizApp.css';

const questions = [
  {
    question: "When did the Chavista regime come into power?",
    options: ["1991", "1999", "2013", "2020"],
    correctAnswer: "1999",
  },
  {
    question:
      "What other positions did Nicolás Maduro hold before becoming President? ",
    options: [
      "Nicolás Maduro did not hold any positions in government before becoming president",
      "Congressman",
      "Congressman, Vice-president and Foreign affairs Minister",
      "Mayor of Caracas, Governor of an Island",
    ],
    correctAnswer: "Congressman, Vice-president and Foreign affairs Minister",
  },
  {
    question: "How many political prisoners during Chávez' era?",
    options: [
      "Hugo Chávez did not imprison political opponents",
      "One political prisoner",
      "At least 5 political prisoners",
      "At least a dozen",
    ],
    correctAnswer: "At least a dozen",
  },
  {
    question: "How many private companies did Chávez expropriated?",
    options: [
      "Almost 1,200 in 10 years",
      "A couple, in 10 years",
      "200, in 5 years",
      "10, during his presidency",
    ],
    correctAnswer: "Almost 1,200 in 10 years",
  },
  {
    question: "How did Chávez try to come to power the first time?",
    options: [
      "Elections",
      "Through a coup",
      "Dive providence",
      "Negotiations with the former government",
    ],
    correctAnswer: "Through a coup",
  },
  {
    question:
      "When did the electoral system began to be electronic in Venezuela?",
    options: ["1999", "1992", "2013", "2004"],
    correctAnswer: "2004",
  },
  {
    question:
      "How many political prisoners does the Chavista regime has currently?",
    options: [
      "About a dozen",
      "There are no political prisoners currently in Venezuela",
      "More than 2,000 political prisoners",
      "1,000 political prisoners approximately",
    ],
    correctAnswer: "More than 2,000 political prisoners",
  },
  {
    question:
      "Who financed the development of the Venezuelan electronic voting system?",
    options: [
      "An NGO",
      "The Venezuelan government",
      "The U.S government",
      "The Venezuelan elite",
    ],
    correctAnswer:
      "The Venezuelan government",
  },
  {
    question: "Who many people died in protests in Venezuela since 1999?",
    options: ["1", "650", "Over 1,000 people", "300"],
    correctAnswer: "Over 1,000 people",
  },
  {
    question: "What is XXI Century Socialism?",
    options: [
      "A political system based on complete state control of the economy and society",
      "An ideology that combines elements of socialism with modern technology and governance",
      "A form of socialism practiced only in South America",
      "Refers to a modern ideology that adapts socialist principles to 21st-century conditions",
    ],
    correctAnswer:
      "Refers to a modern ideology that adapts socialist principles to 21st-century conditions",
  },
  {
    question: "Which freedoms do people lose in socialism?",
    options: [
      "No freedoms are lost in socialism",
      "Freedom of speech, freedom of religion, economic freedom, among others",
      "Economic freedom",
      "More freedoms are gained in socialism",
    ],
    correctAnswer:
      "Freedom of speech, freedom of religion, economic freedom, among others",
  },
  {
    question: "What are some results of socialist/communist regimes?",
    options: [
      "Hunger",
      "Economic crisis",
      "Increment of political prisoners",
      "Lack of access to basic needs such as food, water, electricity, medicine",
      "All of the above",
    ],
    correctAnswer: "All of the above",
  },
  {
    question: "What means do socialist/communist regimes use to stay in power?",
    options: [
      "Persecution of opposition leaders",
      "Control of access to water, electricity, food, and medicine",
      "Control and censorship of media",
      "Illegal incarceration and torture of dissidents",
      "All of the above",
    ],
    correctAnswer: "All of the above",
  },
];

const QuizApp = () => {
  const [startGame, setStartGame] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleStartGame = () => setStartGame(true);

  const handleAnswer = (answer) => {
    setSelectedOption(answer);
    if (answer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    setTimeout(() => {
      if (currentQuestion === questions.length - 1) {
        setShowResults(true);
      } else {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption(null);
      }
    }, 1000);
  };

  const handleRestart = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
    setSelectedOption(null);
  };

  const getResultMessage = () => {
    const percentage = (score / questions.length) * 100;
    if (percentage >= 80) {
      return "Excellent, you know a lot about the reality of Venezuela and Socialism.";
    } else if (percentage >= 60) {
      return "Very good! You know a lot about the situation in Venezuela.";
    } else if (percentage >= 40) {
      return "Not bad, but you could improve your knowledge about Venezuelan politics and 21st-century socialism.";
    } else {
      return "Oops, you can go through the sample and then participate again.";
    }
  };

  return (
    <div className="quiz-container">
      <Menu /> {/* Menú superior reutilizable */}
      {startGame ? (
        showResults ? (
          <div className="results-container">
            <h1 className="results-title">Results</h1>
            <p className="score">
              Your score is: {score} / {questions.length}
            </p>
            <p className="message">{getResultMessage()}</p>
            <button className="restart-button" onClick={handleRestart}>
              Try again
            </button>
          </div>
        ) : (
          <div className="question-container">
            <h1 className="question-title">
              Question {currentQuestion + 1} of {questions.length}
            </h1>
            <p className="question-text">{questions[currentQuestion].question}</p>
            <div className="options-container">
              {questions[currentQuestion].options.map((option, index) => (
                <button
                  key={index}
                  className={`option-button ${
                    selectedOption
                      ? option === questions[currentQuestion].correctAnswer
                        ? "correct"
                        : option === selectedOption
                        ? "incorrect"
                        : ""
                      : ""
                  }`}
                  onClick={() => handleAnswer(option)}
                  disabled={!!selectedOption}
                >
                  {option}
                </button>
              ))}
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: `${((currentQuestion + 1) / questions.length) * 100}%`,
                }}
              />
            </div>
          </div>
        )
      ) : (
        <div className="start-container">
          <h1 className="start-title">Venezuelan Political Trivia</h1>
          <p className="start-subtitle">Test your knowledge!</p>
          <button className="start-button" onClick={handleStartGame}>
            ¡Play!
          </button>
        </div>
      )}
    </div>
  );
};

export default QuizApp;
