import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import QuizApp from './components/quiz/QuizApp';
import Chatbot from './components/chatbot/Chatbot';
import ModelViewer from './components/ModelViewer/ModelViewer';

function App() {
  return (
      <Router basename="/galeries/a-app-3">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/game" element={<QuizApp />} />
          <Route path="/3d-viewer" element={<ModelViewer modelPath="/models/helicoide_model.glb" />} />
          <Route path="/chatbot" element={<Chatbot />} />
        </Routes>
      </Router>
  );
}

export default App;
