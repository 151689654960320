import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css'; // Crea un archivo CSS específico para el menú si es necesario

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div className="menu">
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Organization Logo" className="logo_menu" />
      <button className="home-button" onClick={() => navigate("/")}>
        Home
      </button>
    </div>
  );
};

export default Menu;
