import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="header">
        <img src="/galeries/a-app-3/logo.png" alt="Logo" className="logo-home" />
        <div className="title-container">
          <div className="title">VENEZUELA:</div>
          <div className="subtitle">the Deadly Toll of Socialism</div>
        </div>
      </div>
      <div className="content">
        <div className="buttons">
          <Link to="/game"><button>Quiz</button></Link>
          <Link to="/3d-viewer"><button>Helicoide 3D</button></Link>
          <Link to="/chatbot"><button>AI Assistant</button></Link>
        </div>
      </div>
      <img src="/galeries/a-app-3/man.png" alt="Man" className="bottom-left-image" />
    </div>
  );
};

export default HomePage;
